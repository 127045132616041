import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_header = _resolveComponent("my-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_my_footer = _resolveComponent("my-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_my_header),
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_my_footer)
    ])
  ]))
}