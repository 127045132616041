import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/images/me.jpg'


const _hoisted_1 = { class: "sidenav menu has-background-white-ter" }
const _hoisted_2 = { class: "section" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "menu-label" }
const _hoisted_5 = { class: "menu-list" }
const _hoisted_6 = { class: "menu-label" }
const _hoisted_7 = { class: "menu-list" }
const _hoisted_8 = { class: "menu-label" }
const _hoisted_9 = { class: "menu-list" }
const _hoisted_10 = { class: "menu-label" }
const _hoisted_11 = { class: "menu-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_o_select = _resolveComponent("o-select")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("figure", { class: "image is-128x128" }, [
        _createElementVNode("img", {
          class: "is-rounded",
          src: _imports_0
        })
      ], -1)),
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("h2", { class: "subtitle" }, "Bervianto Leo P", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_o_select, {
        modelValue: _ctx.$i18n.locale,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
        placeholder: "Select a name"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              value: option,
              key: `locale-${option}`
            }, _toDisplayString(option), 9, _hoisted_3))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('navigation.experience')), 1),
      _createElementVNode("ul", _hoisted_5, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/experience" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.experience')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/portofolio" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.portofolio')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/projects" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.projects')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('navigation.community')), 1),
      _createElementVNode("ul", _hoisted_7, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/community" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.community')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/community/speaking" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.speaking')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/community/blog" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.blog')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('navigation.aboutme')), 1),
      _createElementVNode("ul", _hoisted_9, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/certification" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.certification')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/about" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.contact')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('navigation.others')), 1),
      _createElementVNode("ul", _hoisted_11, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/donate" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.donate')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/favorite-audio" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.favoritesong')), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/songs" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('navigation.songrecommendation')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}